import { PlanFormDto } from '@/models/Plan';
import { isObjectValid, required, ValidationResult } from './form';

export interface CreatePlanFormValidity {
  type: ValidationResult;
  professionalId: ValidationResult;
}

export interface UpdatePlanFormValidity {
  professionalId: ValidationResult;
}

export function isCreatePlanFormValid(form: CreatePlanFormValidity) {
  return isObjectValid<CreatePlanFormValidity>(form);
}

export function isUpdatePlanFormValid(form: UpdatePlanFormValidity) {
  return isObjectValid<UpdatePlanFormValidity>(form);
}

export function createPlanForm(plan: PlanFormDto): CreatePlanFormValidity {
  const type = required(plan.type);
  const professionalId = required(plan.professionalId);

  return {
    type,
    professionalId,
  };
}

export function updatePlanForm(plan: PlanFormDto): UpdatePlanFormValidity {
  const professionalId = required(plan.professionalId);

  return {
    professionalId,
  };
}
