import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "l-container" }
const _hoisted_2 = {
  key: 0,
  class: "p-grid"
}
const _hoisted_3 = { class: "p-col-12" }
const _hoisted_4 = {
  key: 1,
  class: "card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_PatientPlansList = _resolveComponent("PatientPlansList")!
  const _component_CardComponent = _resolveComponent("CardComponent")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_PlanCreateUpdateFormModal = _resolveComponent("PlanCreateUpdateFormModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.$t(_ctx.route.meta.breadcrumb ? _ctx.route.meta.breadcrumb[0].label : ''),
      "heading-icon": "icon-folder"
    }, {
      right: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button p-button-icon",
          disabled: !_ctx.userCanAddPlan(),
          onClick: _ctx.onNewPlanClicked
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", {
              class: "icon-plus",
              "aria-hidden": "true"
            }, null, -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('plan.newFollowUp')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("main", _hoisted_1, [
      _withDirectives(_createVNode(_component_LoadingComponent, { "spinner-color": "lavender-700" }, null, 512), [
        [_vShow, _ctx.loadingPlans]
      ]),
      (!_ctx.loadingPlans)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.plans?.length)
                ? (_openBlock(), _createBlock(_component_CardComponent, {
                    key: 0,
                    cardTitle: ''
                  }, {
                    cardContent: _withCtx(() => [
                      _createVNode(_component_PatientPlansList, {
                        patient: _ctx.patient,
                        plans: _ctx.plans,
                        onPlanDetailClick: _ctx.onPlanDetailClicked,
                        onPlanEditClick: _ctx.onPlanEditClicked
                      }, null, 8, ["patient", "plans", "onPlanDetailClick", "onPlanEditClick"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_EmptyState, {
                      heading: _ctx.$t('plan.isEmtyTitle'),
                      text: _ctx.$t('plan.isEmptyMessage'),
                      secondary: false,
                      icon: "icon-folder"
                    }, null, 8, ["heading", "text"])
                  ]))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PlanCreateUpdateFormModal, {
      ref: "newPlanModalRef",
      planOnEdit: _ctx.isEditModal,
      "plan-list": _ctx.plans,
      "patient-id": _ctx.patientId,
      onPlansUpdated: _ctx.onPlansUpdated
    }, null, 8, ["planOnEdit", "plan-list", "patient-id", "onPlansUpdated"])
  ], 64))
}