
import { defineComponent, ref, computed, Ref, onMounted, PropType } from 'vue';
import { Plan, PlanFormDto, PlanType, UpdatePlanFormDto } from '@/models/Plan';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';
import { ServiceError } from '@/services/util/ServiceError';
import { planService } from '@/services/PlanService';
import { createPlanForm, isCreatePlanFormValid, isUpdatePlanFormValid, updatePlanForm } from '@/validation/planForm';
import { userService } from '@/services/UserService';
import { UserRole, UserTableDto } from '@/models/User';
import { useProfileStore } from '@/store/profile.module';
import inputHelper from '@/helpers/Input.helper';

export default defineComponent({
  emits: ['plans-updated'],
  props: {
    planOnEdit: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    planList: {
      type: Array as PropType<Plan[] | undefined>,
    },
    patientId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const toast = useToast();
    const { availableLocales } = useI18n();
    const storeProfile = useProfileStore();
    const userIsSpecialist = computed(() => storeProfile.isSpecialist);
    const showDialog = ref(false);
    const showValidation = ref(false);
    const submitting = ref(false);
    const languageOptions = ref(inputHelper.getLangOptions(availableLocales));
    const plan: Ref<Plan> = ref({} as Plan);
    const planFormDto: Ref<PlanFormDto> = ref({} as PlanFormDto);
    const validatedCreatePlanForm = computed(() => createPlanForm(planFormDto.value));
    const validatedUpdatePlanForm = computed(() => updatePlanForm(planFormDto.value));
    const patientId = computed(() => props.patientId);
    const editMode = computed(() => props.planOnEdit);
    const modalLabel = computed(() => (editMode.value ? t('plan.edit') : t('plan.new')));

    const valid = computed(() =>
      props.planOnEdit
        ? isUpdatePlanFormValid(validatedUpdatePlanForm.value)
        : isCreatePlanFormValid(validatedCreatePlanForm.value),
    );
    const specialistOptions: Ref<UserTableDto[] | undefined> = ref();
    const disabledProperty = computed(() => {
      if (props.planOnEdit) {
        return true;
      }
      if (userIsSpecialist.value && planFormDto.value.professionalId) {
        return true;
      }

      return false;
    });

    const planTypeOptions = ref([
      { label: `${t('plan.types.IMPROVE')}`, value: PlanType.Improve },
      { label: `${t('plan.types.WIN_1')}`, value: PlanType.Win_1 },
      { label: `${t('plan.types.WIN_2')}`, value: PlanType.Win_2 },
      { label: `${t('plan.types.WIN_3')}`, value: PlanType.Win_3 },
    ]);

    onMounted(async () => {
      await fillSpecialistDropdown();
    });

    const openDialog = (defaultPlan: Plan) => {
      if (!defaultPlan) {
        return;
      }
      showValidation.value = false;
      showDialog.value = true;
      plan.value = defaultPlan;
      planFormDto.value = new PlanFormDto(defaultPlan);
    };

    const onSubmit = async () => {
      showValidation.value = true;
      if (!valid.value) {
        return;
      }
      try {
        if (editMode.value) {
          return modifyPlan(new UpdatePlanFormDto(planFormDto.value), plan.value.id);
        } else {
          return createPlan(planFormDto.value);
        }
      } catch (error) {
        console.log(error);
      }
    };

    async function createPlan(createdPlan: PlanFormDto) {
      submitting.value = true;
      const result = await planService.create(patientId.value, createdPlan);
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorCreateFollowUp')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successCreateFollowUp')}`, life: 3000 });
        showDialog.value = false;
      }

      emit('plans-updated');
    }

    async function modifyPlan(modifiedPlan: UpdatePlanFormDto, idPlan: string) {
      submitting.value = true;
      const result = await planService.update(patientId.value, idPlan, modifiedPlan);
      submitting.value = false;
      if (result instanceof ServiceError) {
        toast.add({
          severity: 'error',
          summary: `${t('messages.notifications.errorEditFollowUp')} ${t('messages.notifications.tryLater')}`,
          life: 3000,
        });
      } else {
        toast.add({ severity: 'success', summary: `${t('messages.notifications.successEditFollowUp')}`, life: 3000 });

        showDialog.value = false;
      }
      emit('plans-updated');
    }

    async function fillSpecialistDropdown() {
      const result = await userService.findAll([UserRole.SPECIALIST]);
      if (!(result instanceof ServiceError)) {
        specialistOptions.value = result;
      }
    }

    return {
      showValidation,
      validatedCreatePlanForm,
      validatedUpdatePlanForm,
      submitting,
      planFormDto,
      showDialog,
      specialistOptions,
      userIsSpecialist,
      languageOptions,
      planTypeOptions,
      openDialog,
      onSubmit,
      disableProperty: disabledProperty,
      modalLabel,
      editMode,
    };
  },
});
