
import { defineComponent, onMounted, PropType, Ref, ref, computed } from 'vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import CardComponent from '@/components/CardComponent.vue';
import { Patient } from '@/models/Patient';
import { useRoute } from 'vue-router';
import EmptyState from '@/components/EmptyState.vue';
import PatientPlansList from './PatientPlansList.vue';
import { planService } from '@/services/PlanService';
import { ServiceError } from '@/services/util/ServiceError';
import router from '@/router';
import { Plan } from '@/models/Plan';
import LoadingComponent from '@/components/LoadingComponent.vue';
import PlanCreateUpdateFormModal from '@/components/plans/PlanCreateUpdateFormModal.vue';
import { useProfileStore } from '@/store/profile.module';
import dateFormat from '@/helpers/DateFormat.helper';

export default defineComponent({
  components: {
    HeadingComponent,
    PatientPlansList,
    CardComponent,
    EmptyState,
    LoadingComponent,
    PlanCreateUpdateFormModal,
  },
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const storeProfile = useProfileStore();
    const route = useRoute();
    const newPlanModalRef = ref();
    const plans: Ref<Plan[] | undefined> = ref();
    const loadingPlans = ref(false);
    const isEditModal = ref(false);
    const patientId = computed(() => props.patient.id);
    const isAdminUser = computed(() => storeProfile.isAdmin);

    const fetchPlans = async () => {
      loadingPlans.value = true;
      const result = await planService.findAll(props.patient.id);
      if (!(result instanceof ServiceError)) {
        plans.value = [...result].sort(
          (a, b) => dateFormat.getTimeFromDateString(b.creationDate) - dateFormat.getTimeFromDateString(a.creationDate),
        );
      }
      loadingPlans.value = false;
    };

    onMounted(async () => {
      await fetchPlans();
    });

    const onNewPlanClicked = () => {
      isEditModal.value = false;
      const newPlan = {} as Plan;
      newPlanModalRef.value.openDialog(newPlan);
    };

    const onPlanDetailClicked = async (planSelected: Plan) => {
      await router.push(`/patient/${props.patient.id}/plan-detail/${planSelected.id}`);
    };

    const onPlanEditClicked = (planSelected: Plan) => {
      isEditModal.value = true;
      newPlanModalRef.value.openDialog(planSelected);
    };

    function userCanAddPlan() {
      return isAdminUser.value;
    }

    const onPlansUpdated = async () => {
      await fetchPlans();
    };

    return {
      loadingPlans,
      newPlanModalRef,
      route,
      plans,
      isEditModal,
      onPlanEditClicked,
      onNewPlanClicked,
      fetchPlans,
      onPlanDetailClicked,
      userCanAddPlan,
      patientId,
      onPlansUpdated,
    };
  },
});
