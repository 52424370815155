
import dateFormat from '@/helpers/DateFormat.helper';
import { Patient } from '@/models/Patient';
import { Plan, PlanState } from '@/models/Plan';
import { useProfileStore } from '@/store/profile.module';
import { FilterMatchMode } from 'primevue/api';
import { computed, defineComponent, PropType, ref } from 'vue';
import HealzButton from '../healz/ui/HealzButton.vue';

export default defineComponent({
  components: { HealzButton },
  emits: ['plan-detail-click', 'plan-edit-click'],
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
    plans: {
      type: Array as PropType<Plan[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const storeProfile = useProfileStore();
    const isAdminUser = computed(() => storeProfile.isAdmin);
    const isReceptionistUser = computed(() => storeProfile.isReceptionist);
    const isSpecialistUser = computed(() => storeProfile.isSpecialist);

    const showPaginator = computed(() => props.plans && props.plans.length > 10);
    const filters = ref();
    const initFilters = () => {
      filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: 'filterEqualDate' },
      };
    };

    initFilters();

    const onPlanDetailClick = (selectedPlan: any) => {
      if (!userCanShowPlanDetails(selectedPlan)) {
        return;
      }
      emit('plan-detail-click', selectedPlan);
    };

    const onPlanEditClick = (selectedPlan: any) => {
      emit('plan-edit-click', selectedPlan);
    };

    function userCanEditPlan(plan: Plan) {
      return isAdminUser.value && plan.state !== PlanState.Completed && plan.state !== PlanState.Canceled;
    }

    function userCanShowPlanDetails(plan: Plan) {
      if (isAdminUser.value || isReceptionistUser.value) {
        return true;
      }

      if (!storeProfile.userId || !plan.specialist || !isSpecialistUser.value) {
        return false;
      }

      return plan.specialist.id === storeProfile.userId;
    }

    const getTagFromStatePlan = (state: PlanState) => {
      if (state === PlanState.Completed) {
        return 'p-tag p-tag-plan--Completed';
      } else if (state === PlanState.Canceled) {
        return 'p-tag p-tag-plan--Canceled';
      } else {
        return 'p-tag p-tag-plan--InProgress';
      }
    };

    return {
      filters,
      showPaginator,
      dateFormat,
      initFilters,
      onPlanDetailClick,
      onPlanEditClick,
      userCanShowPlanDetails,
      isAdminUser,
      userCanEditPlan,
      getTagFromStatePlan,
    };
  },
});
