import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "specialist-item-wraper" }
const _hoisted_2 = { class: "specialist-item-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_HealzButton = _resolveComponent("HealzButton")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.plans && _ctx.plans.length > 0)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        ref: "plansDataTable",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
        value: _ctx.plans,
        "data-key": "id",
        rows: 10,
        "filter-display": "menu",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        paginator: _ctx.showPaginator,
        "responsive-layout": "scroll",
        "global-filter-fields": ['type', 'state', 'specialist.name', 'specialist.surname'],
        "sort-field": "date",
        "sort-order": -1,
        rowHover: true,
        "removable-sort": "",
        onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPlanDetailClick($event.data)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "type",
            header: _ctx.$t('plan.followUp')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.$t(`plan.types.${slotProps.data?.type}`)), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "state",
            header: _ctx.$t('plan.state')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Tag, {
                class: _normalizeClass(_ctx.getTagFromStatePlan(slotProps.data.state))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`plan.states.${slotProps.data.state}`)), 1)
                ]),
                _: 2
              }, 1032, ["class"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "specialist",
            header: _ctx.$t('plan.specialists-short')
          }, {
            body: _withCtx((slotProps) => [
              _createElementVNode("p", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(slotProps.data?.specialist?.name) + " " + _toDisplayString(slotProps.data?.specialist?.surname), 1)
              ])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "state",
            header: _ctx.$t('plan.date')
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(_ctx.dateFormat.formatDate(_ctx.dateFormat.utcToUserTimezone(slotProps.data?.creationDate))), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('table.actions')
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_HealzButton, {
                disabled: !_ctx.userCanEditPlan(slotProps.data),
                tooltipText: _ctx.$t('common.no-permission'),
                class: "p-button-table",
                onClick: ($event: any) => (_ctx.onPlanEditClick(slotProps.data))
              }, {
                content: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("i", {
                    class: "icon-edit",
                    "aria-hidden": "true"
                  }, null, -1)
                ])),
                _: 2
              }, 1032, ["disabled", "tooltipText", "onClick"]),
              _createVNode(_component_HealzButton, {
                disabled: !_ctx.userCanShowPlanDetails(slotProps.data),
                tooltipText: _ctx.$t('common.no-permission'),
                class: "p-button-table",
                onClick: ($event: any) => (_ctx.onPlanDetailClick(slotProps.data))
              }, {
                content: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("i", {
                    class: "icon-show",
                    "aria-hidden": "true"
                  }, null, -1)
                ])),
                _: 2
              }, 1032, ["disabled", "tooltipText", "onClick"])
            ]),
            _: 1
          }, 8, ["header"])
        ]),
        _: 1
      }, 8, ["filters", "value", "paginator"]))
    : _createCommentVNode("", true)
}